
declare var window: any;

import { Constants } from './constants/local';
import { AppModule } from './modules/app.module';

import { enableProdMode         } from '@angular/core';
import { platformBrowser        } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (Constants.production) {
    enableProdMode();
}

const bootstrap = () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule);
}

(window.cordova ? document : window).addEventListener(
    window.cordova ? 'deviceready' : 'load', bootstrap, false
);
