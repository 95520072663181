
import { Injectable } from '@angular/core';

import { ConsoleService } from '@service/console.service';

@Injectable({
    providedIn: 'root'
})
export class FilesystemService {

    protected session: any = {};

    constructor (private console: ConsoleService) {

    }

    public readSession (key: string) {
        //this.console.info(`Reading session data "${key}"`, 'service');

        if (this.session.hasOwnProperty(key))
            return this.session[key];

        return null;
    }
    
    public writeSession (key: string, value: any) {
        //this.console.info(`Writing session data "${key}"`, 'service');
        this.session[key] = value;
    }

}
