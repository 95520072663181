
import { Routes            } from '@angular/router';
import { RouterModule      } from '@angular/router';
import { PreloadAllModules } from '@angular/router';

const routes: Routes = [
    
    { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) }

];

export const AppRouting = RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    preloadingStrategy: PreloadAllModules
});
