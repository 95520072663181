
declare var window: any;

import { Constants  } from '@constants';

import { NgZone     } from '@angular/core';
import { Injectable } from '@angular/core';

import { ConsoleService } from '@service/console.service';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor (private zone: NgZone,
                 private console: ConsoleService) {

    }

    public bootstrap () {
        this.console.info('Bootstrapping platform', 'service');
    }

    public offset (element: any) {
        let distance: number = element.offsetTop;

        while (element.offsetParent) {
            distance += element.offsetParent.offsetTop;
            element = element.offsetParent;
        }

        return distance;
    }

    public isInView (element: any, offset: number = 0) {
        return window.scrollY + window.innerHeight > this.offset(element) + offset;
    }

    public encapsulate (text: string) {
        /*if (text.indexOf('<p>') !== -1) {
            let ps: any = text.replace('<p>', '').split('</p>');
                ps = ps.filter((el: string) => {
                    return el ? true : false;
                });

            for (let p = 0; p < ps.length; p++) {
                ps[p] = this.encapsulate(ps[p]);
            }

            return '<p>' + ps.join('</p><p>') + '</p>';
        } else {*/
            return '<span class="encapsulate"><span>' + text.replace(/( +|<br>)/gi, '</span></span>$1<span class="encapsulate"><span>') + '</span></span>';
        //}
    }

}
