
import { Constants  } from '@constants';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConsoleService {

    public log (text: string, type: string = '', color: string) {
        type = type ? `[${type}] ` : ``;

        if (! Constants.production)
            console.log(
                `%c ${type}${text}`,
                `color: ${color};
                 font-size: 10px;
                 line-height: 13px;
                 padding-left: 10px;`
            );
    }

    public info (text: string, type: string = '') {
        this.log(text, type, '#2196f3');
    }

    public warn (text: string, type: string = '') {
        this.log(text, type, '#ffc107');
    }

    public error (text: string, type: string = '') {
        this.log(text, type, '#f44336');
    }

    public group (name: string = '') {
        if (! Constants.production)
            name ? console.group(name)
                 : console.groupEnd();
    }

}
