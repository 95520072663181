
import { Component } from '@angular/core';

import { ConsoleService    } from '@service/console.service';
import { PlatformService   } from '@service/platform.service';
import { FilesystemService } from '@service/filesystem.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {

    constructor (private console: ConsoleService,
                 private platform: PlatformService,
                 private filesystem: FilesystemService) {

    }

    ngOnInit () {
        this.console.group('AppComponent');
        this.console.info('Starting');

        this.platform.bootstrap();
    }

    ngOnDestroy () {
        this.console.info('Destroying');
        this.console.group();
    }

}
