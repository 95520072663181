
import { Constants } from '@constants';

import { NgModule                } from '@angular/core';
import { BrowserModule           } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule     } from '@angular/service-worker';

import { AppRouting   } from './app.routing';
import { AppComponent } from './app.component';

@NgModule({
    imports: [
        AppRouting,
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register(
            'ngsw-worker.js', { enabled: Constants.production }
        )
        
    ],
    bootstrap: [ AppComponent ],
    declarations: [ AppComponent ]
})
export class AppModule {

}
